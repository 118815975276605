import styled from 'styled-components'
import { mobileMin, mobileMax } from '../../../constants'

const FlexBox = styled.section`
    display:flex;
    position:relative;
    width:100%;
    align-content:baseline;
    max-height:130%;
    padding-top:70px;
    min-height:100vh;

    @media ${mobileMin}{
        //margin: 50px 0px 0px 50px; VERIFICAR ESTO
        background-color: #fff;
    }

    @media ${mobileMax}{
        background-color:#0249FD;
        // padding-top:115px !important;
    }

    .fondo{
        position:absolute;
        bottom:0%;
        right:0%;
    }
`

// const ImgContainer = styled.div`

//     width:40%;
//     padding: 0px;
//     display:flex;
//     justify-content:center;
//     transition: opacity 400ms ease 0ms;

//     img{
//         height:auto;
//         width:40%;
//         transition: opacity 400ms ease 0ms;
//     }

//     @media ${mobileMax}{
//         width:100%;
//         display:none;

//         img{
//             background-color:#0249FD;
//             max-height:100%;
//             transition: opacity 400ms ease 0ms;
//         }
//     }
// `

const Logotipo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top:100px

`

const TextContainer = styled.div`

    display:flex;
    flex-direction:column;
    justify-content:center;
    background-color:#0249FD;
    align-items:center;
    padding-top:50px;
    color:white;
    border:1px solid;
    border-radius: 16px;
    border-color:#fff;
    height:90%;

    .logo{
        padding-inline:20px;
        top: 20px;
        position:absolute;
        width: 200px;
        margin-bottom:20px;
        background-color:#0249FD;
    }

    .flecha{
        margin-top:20px;
        width:50px;
        height:150px;
    }

    .flecha2{
        padding-inline:10px;
        top: 94%;
        position:absolute;
        width: 70px;
        background-color:#0249FD;  
    }

    .info{
        padding-top:50px;
        margin-bottom:0px;

        h4{
            font-weight:300;
    
            i{
                font-weight:500;
            }
        }
    }

    @media ${mobileMin}{
        width:100%;
        align-items:center;
        margin:30px 30px 50px 30px;
    }

    h1{
        font-weight:700;
        margin:0px;
    }

    h3{
        font-weight:300;

        i{
            font-weight:500;
        }
    }

    @media ${mobileMax}{
        
        margin:10px 10px 30px 10px;


        .flecha2{
            display:none;
        }

        .logo{
            padding-inline:20px;
            top: 91%;
            position:absolute;
            width: 230px;
            margin-bottom:20px;
            background-color:#0249FD;
        }

        h4{
            display:none;
        }
    }
`

const Descripction = styled.div`
    position:relative; 
    display:flex;
    justify-content:center;
    width:100%;
    height:100%;
    background-color:#0249FD;
    text-align:center;

    @media ${mobileMax}{
        flex-direction:column-reverse;
    }

    h0{
        font-size:4em;
        font-weight:700;
    }

    h2{
        font-weight:600;
    }

    button{
            margin:20px;
            font-size:20px;
            border: none;
            color:#2d2d2d;
            font-weight: 400;
            letter-spacing: 1px;
            border-radius: 15px;
            background-color:#ffff;
            font:'Poppins'
            cursor: pointer;
            transition: all 0.3s ease;
            width:200px;
            height:60px;
    }

    button:hover{
        color:#0249FD;
    }
`

const SecondPageS = styled.div`
    height:100vh;
    position:relative;
    background-color:red;
`

export { Descripction, FlexBox, SecondPageS, TextContainer, Logotipo }
