import React from 'react'
import { FooterStyled } from './Footer.styles'

const Footer = () => {
  return (
    <FooterStyled>
        <img src = "logo-blanco.png"/>
    </FooterStyled>
  )
}

export default Footer
