import styled from 'styled-components'
import { mobileMax } from '../../constants'

const StyledNav = styled.header`

    display:flex;
    height:70px;
    width:100%;
    background-color:#ffffff;
    z-index:20;
    position:fixed;
    border-bottom:1px solid #E8E8E8;
    background-color:#2d2d2d;
    border-bottom:none;
    img{
        width:50px;
        height:50px;
        visibility: ${({ open }) => open ? 'hidden' : 'visible'};
    }

`

const NavContainer = styled.nav`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items:center;
    padding: 0px 50px;
    z-index:155;

    a{
        color:#ffffff;
        font-size:0.9rem;
        font-weight:500;
    }
`

const Ul = styled.ul`
    font-size:0.9rem;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    position:relative;
    z-index:1;
    padding-right:100px;

    @media ${mobileMax}{
        flex-flow: column nowrap;
        position: fixed;
        top:0;
        right:0;
        transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
        height:100vh;
        width:80%;
        padding-top:3rem;
        background-color:#ffffff;
        justify-content:start;
        padding-right:0px;
        transition: all 0.3s ease-in-out;
        padding-top:70px;

        li{
            color:#ffffff;
        }
    }
    
    a{
        cursor:pointer;
        z-index:99999999;
        position:relative;
        align-text:center;

        @media ${mobileMax}{
            
            color:#2d2d2d;
            font-size:1rem;
            font-weight:500;

            &:after{
                content: "";
                position: absolute;
                left: 0px;
                bottom: 30px;
                height: 0.5px;
                width: 600px;
                border-radius: 1px;
                background-color: #2d2d2d;
            }
       }

        &:hover:before{
            content:"";
            position:absolute;
            left:0;
            bottom : -10px;
            height: 3px;
            width: 100%;
            border-radius: 8px;
            background-color: #0249FD;
    
           @media ${mobileMax}{
                display:none;
           }
        }

        &.active:before{
            content:"";
            position:absolute;
            left:0;
            bottom : -10px;
            height: 3px;
            width: 100%;
            border-radius: 8px;
            background-color: #0249FD;
    
           @media ${mobileMax}{
                display:none;
           }
        }
    }


    h4{
        margin:20px 10px;
        font-size: 1.25rem;

        @media ${mobileMax}{
            
            color:#2d2d2d;

            &:after{
                content: "";
                position: absolute;
                left: 0px;
                bottom: -10px;
                height: 3px;
                width: 100%;
                border-radius: 8px;
                background-color: rgb(2, 73, 253);
            }
       }
    }

`
export { StyledNav, Ul, NavContainer }
