import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { StyledNav, Ul, NavContainer } from './Nav.styles'
import Burguer from './Burguer/Burguer'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import googleService from '../../services/googleService'

const Nav = () => {
  const listItems = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5']
  const itemsRef = listItems.map(() => useRef(null))
  // useEffect(() => {
  //   setTimeout(() => {
  //   //   const nav = document.querySelector('[class$="list"]').querySelectorAll('a')
  //   // }, 2000)
  // }, [])
  const [open, setOpen] = useState(false)
  const user = useSelector(state => state.user)

  const changeOpen = () => setOpen(!open)
  const logout = () => {
    setOpen(!open)
    googleService.handleLogout()
  }

  const generarClaseActiva = (index) => {
    itemsRef.forEach((link) => {
      if (link.current) { link.current.classList.remove('active') }
    })
    itemsRef[index].current.className += 'active'
    if (index === 4) {
      googleService.handleLogout()
    }
  }

  return (
    <StyledNav open = {open}>
      <NavContainer>
            { !open ? <Link to ='/'><img src = "./ICONO-BLANCOO.svg"></img></Link> : <Link to ='/'><img src = "/icono.svg"></img></Link>}
          <Burguer open = {open} changeValue = {changeOpen}></Burguer>
          <Ul className = {'list'} open = {open}>
              <h4><Link ref = {itemsRef[0]} to = '/' onClick = { open ? changeOpen : () => generarClaseActiva(0)}>Página principal</Link></h4>
              { user.isAuth === false
                ? <h4><Link ref = {itemsRef[1]} to = '/login'onClick = {open ? changeOpen : () => generarClaseActiva(1)}>Registrarse</Link></h4>
                : <>
                <h4><Link ref = {itemsRef[2]} to = '/ticket' onClick = {open ? changeOpen : () => generarClaseActiva(2)}>Nuevo pasaje</Link></h4>
                <h4><Link ref = {itemsRef[3]} to = '/pasajes' onClick = {open ? changeOpen : () => generarClaseActiva(3)}>Mis Pasajes</Link></h4>
                <h4><Link ref = {itemsRef[4]} to = '/' onClick = {open ? logout : () => generarClaseActiva(4)}> Cerrar Sesión</Link></h4>
                </>
                }
          </Ul>
        </NavContainer >
    </StyledNav>
  )
}

export default Nav
