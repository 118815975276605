import styled from 'styled-components'

const BolitaStyled = styled.button`

    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 30px;
    opacity: 0.2;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 10px;
    transition: all 0.3s ease-out 0s;

    button .inactiva{
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: 0;
        background: 0 0;
    }

`

export default BolitaStyled
