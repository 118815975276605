import React from 'react'
import PropTypes from 'prop-types'

const InformationContainer = ({ number, title, description, description2, urlFoto, tipo }) => {
  return (
    <section className = {tipo}>
        <div>
          <div className = "cajitaTitulo"> <h2 className='numero'>{number}</h2><h2><i>{title}</i></h2></div>
          {/* <div></div> */}
          <div className = "cajitaDescripcion">
            <p>{description}</p>
            <p>{description2}</p>
          </div>
        </div>
        <div className = {`foto ${tipo}`} >
          <img src = {urlFoto}></img>
        </div>
    </section>
  )
}

InformationContainer.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  urlFoto: PropTypes.string,
  tipo: PropTypes.string
}

export default InformationContainer
