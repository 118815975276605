import axios from 'axios'
import { config } from '../constants'
const baseUrl = config.url.API_URL + '/checker'

const getOrigenes = async () => {
  const response = await axios.post(`${baseUrl}/origins`, { withCredentials: true })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Error:\t')
  }
}

const getDestinos = async (origen) => {
  const data = {
    origen
  }
  const response = await axios.post(`${baseUrl}/destinations`, data, { withCredentials: true })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Error:\t')
  }
}

const getHoras = async (date, origen, destino) => {
  const datos = {
    date,
    origen,
    destino
  }
  try {
    const response = await axios.post(`${baseUrl}/time`, datos, { withCredentials: true })
    return response.data
  } catch (e) {
    return new Error('Error')
  }
}

export {
  getOrigenes,
  getDestinos,
  getHoras
}
