import React from 'react'
import { Descripction, FlexBox } from './Dashboard.styles'

const Dashboard = () => {
  return (
    <FlexBox>
      <Descripction>Hola</Descripction>
    </FlexBox>

  )
}

export default Dashboard
