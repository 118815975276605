import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { nombreTrenesValidation, apellidoTrenesValidation, dniTrenesValidation, correoTrenesNuevoUsuarioValidation, passwordTrenesNuevoUsuarioValidation, passwordTrenesConfirmationValidation } from '../../../utils/inputValidation'
import { Input, InputError } from '../../Forms'
import PropTypes from 'prop-types'
import { mobileMax } from '../../../constants'
import styled from 'styled-components'
// import googleService from '../../services/googleService'
import checkerDatosService from '../../../services/checkerDatosService'
import { LoadingSpinner } from '../../../components'

// import axios from 'axios'

export const FormTrenes = () => {
  const [error, setError] = useState({ estado: false, mensaje: '' })
  const [registroExitoso, setRegistroExitoso] = useState(false)
  const [loading, setLoading] = useState(false)

  const methods = useForm({ shouldUnregister: false })

  const onSubmit = methods.handleSubmit(async (data) => {
    setLoading(true)

    if (data.passwordTrenes !== data.passwordConfirmation) {
      setError({ estado: true, mensaje: 'Las contraseñas no coinciden' })
    } else {
      try {
        const response = await checkerDatosService.nuevoUsuarioTrenes(data.nombre, data.apellido, data.dni, data.correoTrenes, data.passwordTrenes)

        if (response) {
          setLoading(false)
          setError({ estado: false, mensaje: '' })
          setRegistroExitoso(true)
          // googleService.checkUser(dispatch)
        //   window.location = config.url.LOCAL_URL + ''
        }
      } catch (e) {
        setLoading(false)

        setError({ estado: true, mensaje: e.message })
      }
    }
  })

  return (

    <FormProvider {...methods} >
        {
            registroExitoso
              ? <p className="flex items-center gap-1 px-2 font-semibold text-green-500 bg-green-100 rounded-md mb-5">
            Registro exitoso: Revise su casilla de correo y spam para validar su cuenta de trenes argentinos.
          </p>
              : <StyledForm3 onSubmit={ e => e.preventDefault()}
        noValidate
        className='container'>
          <Input {...nombreTrenesValidation}/>
          <Input {...apellidoTrenesValidation}/>
          <Input {...dniTrenesValidation}/>
          <Input {...correoTrenesNuevoUsuarioValidation}/>
          <Input {...passwordTrenesNuevoUsuarioValidation}/>
          <Input {...passwordTrenesConfirmationValidation}/>
          <button
            onClick={onSubmit}
            className="flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-800"
          >
          Registrarse
          </button>
          {loading && (<LoadingSpinner></LoadingSpinner>)}
          {error.estado === true ? <InputError message = {error.mensaje}></InputError> : '' }

      </StyledForm3>
        }

    </FormProvider>
  )
}

FormTrenes.propTypes = {
  success: PropTypes.boolean,
  handleSuccess: PropTypes.func,
  handleNext: PropTypes.func,
  viaje: PropTypes.object
}

const StyledForm3 = styled.div`
    background-color:rgb(255 255 255)
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center
    
    @media ${mobileMax}{
        text-align:center;

        label{
          padding-left:0px;
          align-self:center;
      }
    }

    div{
      width:100%;
    }


    button{
      margin-top:5px;
      margin-bottom:10px;
      width:100%;
      height:40px;
      font-weight:400;
      font-size:0.8rem;
    }


    input, select{
        outline: none;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius 5px;
        border-color:rgb(209 213 219);
        border-width: 1px;
        border-radius: 0.375rem;
        padding: 0 15px;
        height: 41px;
        margin: 8px 0;
        width: 100%;
    }

    label{
        font-size: 0.6rem;
        font-weight: 600;
        color: #2e2e2e;
        align-self:baseline;
    }


    h1 , h2{
        font-weight:600;
        margin:  10px 10px 10px 10px;
    }

`
