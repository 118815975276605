import React, { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { findInputError } from '../../../utils/findInputError'
import { isFormInvalid } from '../../../utils/isFormInvalid'
import { AnimatePresence } from 'framer-motion'
import { InputError } from './InputError'
import ContainerFormInput from '../FormNuevoViaje/FormInput.styles'

export const Select = ({ label, placeholder, validation, name, arrayValues, id, handleChange, disabled }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext({ shouldUnregister: false })

  const inputError = findInputError(errors, label)
  const isInvalid = isFormInvalid(inputError)

  return (
        <ContainerFormInput>
        <label>{label}</label>
        <AnimatePresence mode="wait" initial={false}>
            {isInvalid && (
              <InputError
                message={inputError.error.message}
                key={inputError.error.message}
              />
            )}
          </AnimatePresence>
        <select onInput={handleChange} id = {id} disabled = {disabled}{...register(name, validation, { shouldUnregister: false })} >
            <option value= "" disabled hidden>{placeholder}</option>
            {arrayValues.map((value, i) =>
                <option key= {i} value = {value} >{value}</option>
            )}
        </select>
        </ContainerFormInput>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  validation: PropTypes.Object,
  arrayValues: PropTypes.array,
  disabled: PropTypes.boolean,
  handleChange: PropTypes.func
}
