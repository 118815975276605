import React from 'react'
import Acordeon from './Acordeon'
import styled from 'styled-components'
import { PreInfo } from '../PaginaPrincipal/Information/Information.styles'
import { mobileMax } from '../../constants'

const Estilo = styled.div`    
    display:flex;
    flex-direction:column;
    width: 100%;
    margin-inline:20%;
    padding-bottom:60px;
    align-items:center;
    
    @media ${mobileMax}{
        margin-inline:0%;
    }`

const PreguntasFrecuentes = () => {
  return (
    <section style={
        { width: '100%' }
    }>
        <PreInfo><h3>PREGUNTAS FRECUENTES</h3></PreInfo>
        <Estilo>
            <Acordeon></Acordeon>
        </Estilo>
    </section>
  )
}

export default PreguntasFrecuentes
