const sizes = {
  mobile: '768px'

}

const prod = {
  url: {
    API_URL: 'https://server2.techiflo.com',
    LOCAL_URL: 'http://techiflo.com'
  }
}
const dev = {
  url: {
    API_URL: 'http://localhost:4000',
    LOCAL_URL: 'http://localhost:3000'
  }
}

const config = process.env.REACT_APP_ENVIRONMENT === 'development' ? dev : prod
console.log(process.env.REACT_APP_ENVIRONMENT)

const mobileMin = `(min-width: ${sizes.mobile})`
const mobileMax = `(max-width: ${sizes.mobile})`

export { mobileMin, mobileMax, config }
