import axios from 'axios'
import { setIsAuthenticated, setUser, setValidate, setLoading, setPasajesLimite } from '../features/auth/userSlice'
import { config } from '../constants'
//  const productionUrl = 'https://ecommercecoderr.herokuapp.com/api/carrito'

const registrarse = async (email, password) => {
  try {
    const res = await axios.post(`${config.url.API_URL}/registrarse`, { email, password }, { withCredentials: true })

    if (res.status === 200) {
      return res.status
    } else { // Usuario ya existente
      throw new Error('Usuario ya existente')
    }
  } catch (error) { // Errores inesperados
    throw new Error('Usuario ya existente')
  }
}

const login = async (email, password) => {
  try {
    const res = await axios.post(`${config.url.API_URL}/login`, { email, password }, { withCredentials: true })

    if (res.status === 200) {
      JSON.stringify(res.data)
    } else {
      throw new Error('Error en el login')
    }
  } catch (error) {
    // Maneja los errores

    throw new Error('Error en el login')
  }
}

const redirectToGoogleSSO = () => {
  const timeout = setTimeout(() => {
    window.location = `${config.url.API_URL}/auth/login/google`
  }, 100)

  return () => clearTimeout(timeout)
}

const checkUser = async (dispatch) => {
  try {
    const response = await axios.get(`${config.url.API_URL}/auth/user`, { withCredentials: true })

    if (response.status === 200) {
      if (response.data.validado === true) {
        dispatch(setValidate(true))
      } else {
        dispatch(setValidate(false))
      }
      if (response.data.cantPasajes === 2) {
        dispatch(setPasajesLimite())
      }
      dispatch(setIsAuthenticated(true))
      dispatch(setUser(response.data))
    } else {
      dispatch(setValidate(false))
      dispatch(setIsAuthenticated(false))
      dispatch(setUser(false))
    }
  } catch (err) {
    dispatch(setValidate(false))
    dispatch(setIsAuthenticated(false))
    dispatch(setUser(false))
  }
  dispatch(setLoading(true))
}

const handleLogout = async () => {
  const timeout = setTimeout(() => {
    window.location = `${config.url.API_URL}/logout`
  }, 100)

  return () => clearTimeout(timeout)
}

const setDatosUsuario = async informacion => {
  await axios.post(`${config.url.API_URL}/user/informacion`, informacion, { withCredentials: true })
}

export default {
  registrarse,
  login,
  redirectToGoogleSSO,
  checkUser,
  setDatosUsuario,
  handleLogout
}
