import styled from 'styled-components'
import { mobileMax, mobileMin } from '../../../constants'

const PreInfo = styled.div`
    background-color:#FF4B27;
    width:100%;
    padding:15px;
    padding-left:20px;

    h3{
        color:#ffffff;
        width:40%;
        font-weight:600;
    }

    @media ${mobileMax}{
        h3{
            color:#ffffff;
            width:100%;
            font-weight:600;
        }
    }


`

const Container = styled.div`
    display:flex;
    flex-direction:column;
    width: 100%;
    margin-inline:20%;
    align-items:center;
    margin-bottom:60px;
    padding-bottom:80px;

    div{
        display:flex;
        flex-directon:column;
        flex-wrap:wrap;
        justify-content:space-between;
        text-align:center;
    }

    section.alarma{
        border-color:#FF4B27;
    }

    section{
        padding-left:10px;
        width:100%;
        flex-direction:row;
        border: 1px solid;
        border-color:#0249FD;
        justify-content:center;
        border-radius:16px;
    }

    section div{
        width:70%;
        text-align:left;
    }


    // CAJA PARA EL NOMBRE
    .cajitaTitulo{
        display: flex;
        justify-content: start;
        margin-top:20px;
        width:100%;
        display:flex;
        flex-direction:row;

        .numero{
            color:#FF4B27;
        }

        h2{
            margin:2px 5px;
            font-weight:400;
        }
    }

    .cajitaDescripcion{
        margin:20px 20px 20px 5px;
        width:100%;

        
    }

    .foto{
        width:30%;
        border-radius:0px 16px 16px 0px;
        display:flex;
        align-items:center;
        justify-content:center;

        img{
            width:60%;
            height:60%;
        }
    }

    .foto.impar{
        background-color:#CBCBCB;
    }

    section.impar{
        border-color:#CBCBCB
    }

    .foto.par{
        background-color: #0249FD;
    }

    .foto.alarma{
        background-color: #FF4B27;
    }


    h1{
        margin-top:20px;
    }

    h2{
        margin-top:10px;
    }

    @media ${mobileMax}{
        display:flex;
        flex-direction:column;
        margin-inline:0;
        padding-bottom:0px;

        h2:nth-child(4):before{
            bottom:-80px;
         }

        .cajitaDescripcion{
            margin:30px 20px;
            width:100%;
    
    
        }

        section{
            margin: 20px 10px;
        }

        p{
            text-align:center;
        }
    }

    @media ${mobileMin}{
        margin-inline:8%;

        div{
            display:flex;
            flex-directon:column;
            flex-wrap:wrap;
        }
        section{
            width:45%;
            margin:30px 10px;
            height:30%;
        }



    }

    h1{
        font-size:1.65rem;
        margin-bottom:40px;
        text-align:center;
    }

    h2{
        font-size:1.25rem;
        margin-bottom:20px;
        text-align:left;
    }
    h4{
        margin-bottom:20px;
    }

    p{
        font-size:0.9em;
    }





`

export { Container, PreInfo }
