import axios from 'axios'
//  const productionUrl = 'https://ecommercecoderr.herokuapp.com/api/carrito'
import { config } from '../constants'

const registrarse = async (email, password) => {
  try {
    const res = await axios.post(`${config.url.API_URL}/registro`, { withCredentials: true }, { params: { email, password } })
    if (res.ok) {
      const data = await res.json()
      if (!data.success) {
        throw new Error('Error en la solicitud')
      }
    }
  } catch (error) {
    // Maneja los errores
    console.error(error)
  }
}

const mandarEmailRecover = async (email) => {
  try {
    const data = {
      email
    }
    // aca poner el la ruta como variable
    const res = await axios.post(`${config.url.API_URL}/emailOTP`, data, { withCredentials: true })
    if (res.ok) {
      const data = await res.json()
      console.log(data)
      return data
    }
  } catch (error) {
    // Maneja los errores
    throw new Error()
  }
}

const chequearOTP = async (otp, email) => {
  try {
    const data = {
      otp,
      email
    }
    // aca poner el la ruta como variable
    const res = await axios.post(`${config.url.API_URL}/checkOTP`, data, { withCredentials: true })
    if (res.ok) {
      const data = await res.json()
      console.log(data)
      return data
    }
  } catch (error) {
    // Maneja los errores
    throw new Error()
  }
}

const setearPassword = async (otp, email, password) => {
  try {
    const data = {
      otp,
      email,
      password
    }
    // aca poner el la ruta como variable
    const res = await axios.post(`${config.url.API_URL}/setPassword`, data, { withCredentials: true })
    if (res.ok) {
      const data = await res.json()
      console.log(data)
      return data
    }
  } catch (error) {
    // Maneja los errores
    throw new Error()
  }
}

export default {
  registrarse,
  mandarEmailRecover,
  chequearOTP,
  setearPassword
}
