import styled from 'styled-components'
import { mobileMax } from '../../constants'

const StyledTitle = styled.div`


    font-weight:500;
    margin:  10px 10px 20px 10px;
    font-size:1.75em; 
    font-style:italic;
    position:relative;
    width:fit-content;

    &:before{
        content:"";
        position:absolute;
        left:0;
        bottom : -2px;
        height: 3px;
        width: 100%;
        border-radius: 8px;
        background-color: #4070f4;

       @media ${mobileMax}{
            display:none;
       }
    }


`

export default StyledTitle
