import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TitleWithLine = ({ frase }) => {
  return (
    <H2>
        <h4><span>{frase}</span></h4>
    </H2>
  )
}

TitleWithLine.propTypes = {
  frase: PropTypes.string
}

const H2 = styled.div`

    width:100%;

    h4 {
        text-align: center; 
        border-bottom: 1.5px solid rgb(75,85,99); 
        line-height: 0.1em;
        margin: 20px 0 20px; 
        border-color:rgba(75,85,99,0.8);
        

    span{ 
        background:#fff; 
        padding:0 10px; 
        color:rgba(75, 85, 99,0.8);
        font-weight:500;
    }
`

export default TitleWithLine
