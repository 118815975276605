import styled from 'styled-components'
import { mobileMax } from '../../../constants'

const StyledBurguer = styled.div`
    width:2rem;
    height:2rem;
    position:fixed;
    top:22px;
    right:20px;
    display:flex;
    z-index:20;
    display:none;

    @media ${mobileMax}{
        display:flex;
        justify-content:space-around;;
        flex-flow:column nowrap;
        z-index:20;
    }

    div{
        width:2rem;
        height:0.3rem;
        background-color: ${({ open }) => open ? '#2d2d2d' : '#ffffff'};
        border-radius:10px;
        transform-origin:1px;
        margin-right:10px;
        transition: all 0.3s linear;

        &:nth-child(1){
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }
        &:nth-child(2){
            transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
            opacity:${({ open }) => open ? 0 : 1}
        }
        &:nth-child(3){
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
        
    }

`
export { StyledBurguer }
