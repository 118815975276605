import React, { useRef } from 'react'
import { MainCointainer } from './PaginaPrincipal.styles.js'
import { Cover, Information } from './index.js'
import Footer from '../Footer/Footer.js'
import PreguntasFrecuentes from '../PreguntasFrecuentes/PreguntasFrecuentes.js'

const PaginaPrincipal = () => {
  const myRef = useRef()

  return (
    <>
    <MainCointainer>
      <Cover></Cover>
      {/* <Description flexDirection = "row-reverse"
                 header = "Búsqueda de pasajes"
                 header2 = "Recibirás un mensajede Wpp y Correo con una reserva de tu pasaje."
                 header3 = "Pagarás una comisión para obtener tu pasaje."
                 header4 = "Recibirás un link para comprar el pasaje en un plazo de 10 minutos."
                 header5 = "Recibirás tu pasaje vía email"
                 description4 = "Importante: somos un servicio de terceros que busca y reserva pasajes de tren para nuestros clientes. El proceso de compra y pago se realiza a través de Mercado Pago y Trenes Argentinos."
                 imgSrc = "/train4.webp"
                 paddingLeft = {true}
                >
      </Description> */}
      <Information myRef={myRef}></Information>
      {/* <Footer></Footer> */}
      <PreguntasFrecuentes></PreguntasFrecuentes>
      <Footer></Footer>
    </MainCointainer>
    </>
  )
}

export { PaginaPrincipal }
