import React, { useEffect } from 'react'
import { FlexBox } from '../../Forms/Forms.styles'
import { config } from '../../../constants'

const LoginSuccess = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location = config.url.LOCAL_URL + '/ticket'
    }, 2000)
  }, [])

  return (
    <FlexBox style = {{ paddingTop: '120px' }}>
            <h3 className="flex items-center gap-4 px-4 font-semibold text-green-500 bg-green-100 rounded-md mb-5">
              Registro exitoso
            </h3>
    </FlexBox>
  )
}

export default LoginSuccess
