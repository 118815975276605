import { useState } from 'react'

const useCounter = () => {
  const [index, setIndex] = useState(0)

  const incrementCounter = () => {
    setIndex(prevState => prevState + 1)
  }

  const decrementCounter = () => {
    setIndex(prevState => prevState - 1)
  }

  const reset = () => {
    setIndex(0)
  }

  const getValue = () => {
    return index
  }

  return {
    getValue,
    incrementCounter,
    decrementCounter,
    reset
  }
}

export default useCounter
