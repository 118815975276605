import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AcordeonStyled = styled.div`
    width:90%;
`

const PreguntaStyled = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    width:100%;
    border-radius:16px;
    margin:20px 0;
    border: 1px solid #2d2d2d;

    .title{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding: 20px;
        // border-bottom: 1px solid #2d2d2d;
        cursor:pointer;

        h3{
            font-weight:500;
        }

        .signo{
            font-weight:600;
            color:#FF4B27;
        }
    }

    .descripcion{
        padding: 20px;
        min-height:50px;
        display:flex;
        align-items:center;
        justify-content:start;
        font-weight:400;
        color:#2d2d2d;
    }
`

const Acordeon = () => {
  const data = [
    {
      pregunta: '¿Puedo viajar con otra persona?',
      respuesta: 'Sí, la otra persona debe crearse una cuenta única y personal de Te Chiflo ya que las reservas solo pueden hacerse para un pasajero a la vez. No se aseguran asientos contiguos, aunque algunos pasajeros suelen preguntar sobre cambios de asientos.'
    },
    {
      pregunta: '¿Garantizan el pasaje?',
      respuesta: 'No, no garantizamos los pasajes ya que se adquieren a medida que se liberan. Sin embargo, nuestra efectividad es muy alta.'
    },
    {
      pregunta: '¿Puedo transferir mi pasaje a otra persona?',
      respuesta: 'No, los pasajes no son transferibles y están vinculados de forma única al titular original.'
    },
    {
      pregunta: '¿Puedo cambiar la fecha de viaje?',
      respuesta: 'No se puede cambiar la fecha del viaje, pero existe la opción de devolver el pasaje mediante Trenes Argentinos y realizar una nueva reserva a través de Te Chiflo.'
    },
    {
      pregunta: '¿Puedo devolver mi pasaje?',
      respuesta: 'Sí, podés devolver tu pasaje a través de la página de Trenes Argentinos. El reembolso varía entre el 70% y el 90%, dependiendo de la fecha de devolución. La comisión de Te Chiflo no es reembolsable.'
    },
    {
      pregunta: 'Compré el pasaje y no lo recibí en mi correo electrónico. ¿Qué debo hacer?',
      respuesta: 'Una vez que adquirís el pasaje, podés descargarlo ingresando a la sección de "reservas" o "pasajes" en tu cuenta de Trenes Argentinos.'
    },
    {
      pregunta: '¿Cuál es el costo del servicio?',
      respuesta: 'Te Chiflo cobra una comisión de $3500, más el costo del pasaje. Asegurate de tener en cuenta este costo al realizar tus reservas ;)'
    }
  ]

  const [isActive, setActive] = useState(0)

  //   setActive(0)

  return (
    <AcordeonStyled>
        {data.map((element, index) => {
          return (
            <ElementoAcordeon
                key = {index}
                isActive = {isActive === index}
                element = {element}
                setActive = {setActive}
                index = {index}
            ></ElementoAcordeon>
          )
        }
        )}
    </AcordeonStyled>
  )
}

const ElementoAcordeon = ({ element, isActive, setActive, index }) => {
  const cambiarPregunta = () => {
    const nextIndex = isActive ? null : index
    setActive(nextIndex)
  }

  return (
    <PreguntaStyled>
        <div className = "title" onClick = {cambiarPregunta}>
            <h3><i>{element.pregunta}</i></h3>
            <div className='signo'>{isActive ? '-' : '+'}</div>
        </div>
        { isActive && <div className='descripcion'><h4>{element.respuesta}</h4></div>}
    </PreguntaStyled>)
}
ElementoAcordeon.propTypes = {
  element: PropTypes.object,
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  index: PropTypes.number
}

export default Acordeon
