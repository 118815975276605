import React from 'react'
import PropTypes from 'prop-types'
import StyledTitle from './Title.styles'

const Title = ({ name }) => {
  return (
    <StyledTitle>{name}</StyledTitle>
  )
}

Title.propTypes = {
  name: PropTypes.node
}

export default Title
