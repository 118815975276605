import styled from 'styled-components'
import { mobileMax, mobileMin } from '../../constants'

const FlexBox = styled.section`
    display:flex;
    position:relative;
    width:100%;
    align-content:baseline;
    padding-top:70px;
    background-color:#cbcbcb;
    max-height:130%;

    @media ${mobileMin}{
        background-color: #fff;
        min-height:80vh;
    }

    .fondo{
        position:absolute;
        bottom:0%;
        right:0%;
    }
`

const Descripction = styled.div`
    position:relative; 
    display:flex;
    justify-content:center;
    width:100%;
    min-height:80%;

    @media ${mobileMax}{
        flex-direction:column-reverse;
    }

    h0{
        font-size:4em;
        font-weight:700;
    }

    h2{
        font-weight:600;
    }

    button{
            margin:20px;
            border: none;
            color:#fff;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 1px;
            border-radius: 15px;
            background-color:#4070f4;
            cursor: pointer;
            transition: all 0.3s ease;
            width:175px;
            height:55px;
    }

    button:hover{
        color:#000000;
    }
`

export { Descripction, FlexBox }
