import { useState } from 'react'

const useInfo = (object) => {
  const [value, setViaje] = useState(object)

  const changeObject = (e) => {
    setViaje({
      ...value,
      [e.target.name]: e.target.value
    })
  }

  const changeValue = (e) => {
    setViaje({
      origen: e.origen,
      destino: e.destino,
      pasajeros: e.pasajeros,
      fecha: e.fecha
    })
  }

  const getValue = () => {
    return value
  }

  const hasError = () => {
    const hasError = Object.values(value).filter(element => element === null || element === '').length
    return hasError !== 0
  }

  return { getValue, changeObject, hasError, changeValue }
}

export default useInfo
