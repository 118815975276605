import './App.css'
import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { PaginaPrincipal, Forms, Contacto } from './views'
import Login from './views/Login/Login'
import Signup from './views/Auth/Signup.js/Signup'
import LoginSuccess from './views/Auth/LoginSuccess/LoginSuccess'
import Dashboard from './views/Dashboard/Dashboard'
import googleService from './services/googleService'
import { useDispatch, useSelector } from 'react-redux'
import PlantillaReciboPasaje from './views/ReciboPasaje/PlantillaReciboPasaje'
import PlantillaFormTrenes from './views/Forms/FormTrenes/PlantillaFormTrenes'
import { Nav } from './components'
import PreguntasFrecuentes from './views/PreguntasFrecuentes/PreguntasFrecuentes'
import RecoverPassword from './views/Login/RecoverPassword'
import { DeleteConvModal } from './views/ReciboPasaje/ModalDelete'
import { setModalStateInActive } from './features/auth/userSlice'
import pasajesService from './services/pasajesService'

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  useEffect(() => {
    googleService.checkUser(dispatch)
  }
  , [])

  // REVISAR ESTAS FUNCIONES ESTAN RE DUDOSAS

  const deleteTicket = async () => {
    try {
      await pasajesService.deleteTicket(user.ticketToDelete)
      dispatch(setModalStateInActive())
      // vuelvo a renderizar los tickets
      await pasajesService.getTickets(dispatch)
    } catch (error) {
      console.error(error)
    }
  }

  return (
     <div className='app'>
        <Nav></Nav>
        <DeleteConvModal
          modalState = {user.modalState}
          handleYes = {deleteTicket}
          handleNo ={ setModalStateInActive}
          ></DeleteConvModal>
        {!user.loading
          ? <Routes>
          <Route path = '/' element = {<PaginaPrincipal></PaginaPrincipal>}></Route>
          <Route path = "/dash" element = {user.isAuth ? <Dashboard></Dashboard> : <Navigate to = "/"></Navigate>}></Route>
          <Route path = '/ticket' element = {user.isAuth ? <Forms></Forms> : <Navigate to = "/"></Navigate>}></Route>
          <Route path = '/pasajes' element = {user.isAuth ? <PlantillaReciboPasaje></PlantillaReciboPasaje> : <Navigate to = "/"></Navigate>}></Route>
          <Route path = '/contacto' element = {<Contacto></Contacto>}></Route>
          <Route path = '/preguntas' element = {<PreguntasFrecuentes></PreguntasFrecuentes>}></Route>
          <Route path = "/success" element = {<LoginSuccess></LoginSuccess>}></Route>
          <Route path = '/login' element = {user.isAuth ? <Navigate to = "/"></Navigate> : <Login></Login>}></Route>
          <Route path = '/registrarse' element = {user.isAuth ? <Navigate to = "/"></Navigate> : <Signup></Signup> }></Route>
          <Route path = "/error" element = {<h1>Please signup later</h1>}></Route>
          <Route path = "/trenes" element = {<PlantillaFormTrenes></PlantillaFormTrenes>}></Route>
          <Route path = "/lostPassword" element = {<RecoverPassword></RecoverPassword>}></Route>
        </Routes>
          : <></>}
      </div>
  )
}

export default App
