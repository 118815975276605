import React, { useState } from 'react'
import { FlexBox } from '../Forms/Forms.styles'
import { Title } from '../../components'
import { FormRecoverPassword } from './FormRecoverPassword'
import styled from 'styled-components'
import { mobileMax } from '../../constants'
import { FormOTP } from './FormOTP'
import { FormChangePassword } from './FormChangePassword'

const RecoverPassword = () => {
  const [email, setEmail] = useState(null)
  const [page, setPage] = useState(0)
  const [otp, setOTP] = useState(null)

  const pagina = () => {
    switch (page) {
      case 0:
        return <FormRecoverPassword setPage = {setPage} setEmail = {setEmail}></FormRecoverPassword>
      case 1:
        return <FormOTP email={email} setPage={setPage} setOTP={setOTP}></FormOTP>
      case 2:
        return <FormChangePassword otp = {otp} email = {email}></FormChangePassword>
    }
  }
  return (
    <FlexBox>
            <StyledForms2>
                <Flex>
                <Title name = 'Recuperar contraseña'></Title>
                </Flex>
                {
                    pagina()
                }
                <img className = "logo" src = "./LOGO-AZUL.svg"/>
            </StyledForms2>
    </FlexBox>
  )
}

export default RecoverPassword

const Flex = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;

    p{
        color:rgb(37, 99, 235);

        &:hover{
            font-weight:500;
            cursor:pointer;
            border-bottom: 1px solid;
        }
    }


`

const StyledForms2 = styled.div`

    background-color:rgb(255 255 255);
    width: 30%;
    border-radius: 16px;
    border: 2px solid #E8E8E8;
    margin: 10px auto;
    padding: 0px 36px;
    display: flex;
    flex-direction: column;
    align-items:center;

    @media ${mobileMax}{
        text-align:center;
        width:95%;
    }


    input, select{
        outline: none;
        font-size:14px;
        font-weight: 400;
        border-radius 5px;
        border-color:rgb(209 213 219);
        border-width: 1px;
        border-radius: 0.375rem;
        padding: 0 15px;
        height: 42px;
        margin: 8px 0;
    }

    label{
        font-size: .875rem;
        font-weight: 500;
        color: #2e2e2e;
    }


    h1 , h2{
        font-weight:600;
        margin:  10px 10px 10px 10px;
    }

    img{
        width:30%;
        margin-bottom:30px;
    }

`
