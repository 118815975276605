import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Input, InputError } from '../Forms'
import { password1Validation, password2Validation } from '../../utils/inputValidation'
import styled from 'styled-components'
import { mobileMax } from '../../constants'
import PropTypes from 'prop-types'
import login from '../../services/login'

export const FormChangePassword = ({ otp, email }) => {
  const [error, setError] = useState(false)
  const [loginExitoso, setLoginExitoso] = useState(false)
  // const dispatch = useDispatch()
  const methods = useForm({ shouldUnregister: false })
  //   const usuario2 = useForm2({
  //     mail: '',
  //     password: ''
  //   })

  const onSubmit = methods.handleSubmit(async data => {
    console.log('la informacion es', data)
    if (data.password1 === data.password2) {
      console.log(data.password1, data.password2)
      try {
        await login.setearPassword(otp, email, data.password1)
        setLoginExitoso(true)
        setError(false)
      } catch (e) {
        console.log(e)
        setError(true)
      }
    } else {
      setError(true)
    }
  })

  return (
      <FormProvider {...methods} >
          <StyledForm3 onSubmit={ e => e.preventDefault()}
          noValidate
          className='container'>
            {!loginExitoso
              ? <>
              <Input {...password1Validation}/>
              <Input {...password2Validation}/>

              <button
                onClick={onSubmit}
                className="flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-800"
              >
              Cambiar contraseña
              </button>
            </>
              : <p className="flex items-center gap-1 px-2 font-semibold text-green-500 bg-green-100 rounded-md mb-5">
                Contraseña cambiada con exito.
              </p>
            }
            {error === true ? <InputError message = {'No coinciden las contraseñas.'}></InputError> : '' }
        </StyledForm3>
      </FormProvider>
  )
}

FormChangePassword.propTypes = {
  otp: PropTypes.number,
  email: PropTypes.string
}

const StyledForm3 = styled.div`
    background-color:rgb(255 255 255)
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-bottom:20px;
    
    @media ${mobileMax}{
        text-align:center;

        label{
          padding-left:0px;
          align-self:center;
      }
    }

    div{
      width:100%;
    }


    button{
      margin-top:5px;
      margin-bottom:10px;
      width:100%;
      height:40px;
      font-weight:400;
      font-size:0.8rem;
    }


    input, select{
        outline: none;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius 5px;
        border-color:rgb(209 213 219);
        border-width: 1px;
        border-radius: 0.375rem;
        padding: 0 15px;
        height: 41px;
        margin: 8px 0;
        width: 100%;
    }

    label{
        font-size: 0.875rem;
        font-weight: 500;
        color: #2e2e2e;
        align-self:baseline;
    }


    h1 , h2{
        font-weight:500;
        margin:  10px 10px 10px 10px;
    }

`
