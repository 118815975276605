import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

export const DeleteConvModal = ({ modalState, handleNo, handleYes }) => {
  const dispatch = useDispatch()
  return (
    <div
      className={`${
        modalState === 'ACTIVE' ? 'visible' : 'hidden'
      } absolute z-30  h-screen w-screen bg-gray-alpha`}
    >
        <article className="mx-auto mt-24 flex w-[90vw] max-w-lg  flex-col gap-4 rounded-t-lg bg-white p-6 shadow-lg">
            <p className="text-xl text-jet">Desea cancelar su solicitud de pasaje?</p>
          </article>
      <div className=" mx-auto flex w-[90vw] max-w-lg flex-row-reverse rounded-b-lg bg-white pb-5 pr-5  shadow-lg">
        <div>
          <button
          type='button'
          onClick={() => { handleYes() }}
          className="ml-auto h-10 w-20 rounded-3xl bg-main-blue text-white transition-all hover:bg-main-orange">
            Si
          </button>
          <button
          type = 'button'
          onClick={() => { dispatch(handleNo()) }}
          className="ml-5 h-10 w-20 rounded-lg border border-main-blue bg-white text-main-blue transition-all hover:bg-main-blue hover:text-white">
            No
          </button>
        </div>
        {/* <p className="mx-auto mt-2 mr-auto text-sm text-red-500">
            props.errorMessage}
        </p> */}
      </div>
    </div>
  )
}

DeleteConvModal.propTypes = {
  modalState: PropTypes.string.isRequired,
  handleNo: PropTypes.func.isRequired,
  handleYes: PropTypes.func.isRequired
}
