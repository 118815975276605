import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import pasajesService from '../../services/pasajesService'
import { StyledForm } from '../Forms/Forms.styles.js'
import { useNavigate } from 'react-router-dom'
import { setModalStateActive, setTicketToDelete } from '../../features/auth/userSlice.js'

const ReciboPasaje = () => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    // REVISAR ESTA FUNCION ESTA MUY DUDOSA

    const getTickets = async (dispatch) => {
      const response = await pasajesService.getTickets(dispatch)
      return response
    }
    getTickets(dispatch)
  }, [])

  function obtenerIniciales (nombre) {
    // Dividir el nombre en palabras usando los espacios como separadores
    const palabras = nombre.split(' ')
    let iniciales = ''

    if (palabras.length > 1) {
      palabras.forEach(palabra => {
        iniciales += palabra.substring(0, 1)
      })
      return iniciales.toUpperCase()
    } else {
      return palabras[0].substring(0, 3).toUpperCase()
    }
  }

  const deleteTicket = (id) => {
    dispatch(setModalStateActive())
    dispatch(setTicketToDelete(id))
  }

  return (
    <StyledForm>
        <Div>
        <div className="details bg-white rounded-md border-gray-200 border-2 flex flex-col items-start  justify-center max-md:items-center shadow-2xl p-8">
            <div className='flex items-center gap-6 pb-6 pt-4 max-md:flex-col'>
              <img src ={'/group-svgrepo-com.svg'} className = "w-[15%] max-md:w-[40%]"></img>
              <h3>{user.info.nombre} {user.info.apellido}</h3>
            </div>
            <div className=''>
              <p className='pt-4'><b>Email: </b>{user.info.email}</p>
              <p className='pt-4'><b>Código de Área: </b>{user.info.codArea}</p>
              <p className='pt-4'><b>Número: </b>{user.info.numero}</p>
              <p className='pt-4'><b>DNI: </b>{user.info.dni}</p>
              <p className='pt-4'><b>Cuenta de Trenes Argentinos: </b>{user.info.correoTrenes}</p>
            </div>

        </div>
        <Div2>
        { user.viaje
          ? user.viaje.map(viaje => {
            return <>
                <div className='flex w-[48%] mr-[10px] max-md:mr-0 max-md:w-full justify-center  list-none flex-col p-8 bg-white shadow-2xl mb-6 border-gray-200 border-2  transition-height duration-3500 ease-in-out '>
                  <div className=' border-b-2 border-[#4070f4] flex self-end items-end justify-end w-full'>
                    <img src ={'/icono-azul.svg'} className = "pb-2 w-[8%] max-md:w-[15%] "></img>
                  </div>
                  <section className='flex justify-between rounded-md  border-gray-100  pt-9 '>

                      <div className='w-[40%]'>
                        <p className=' font-bold text-4xl'> {obtenerIniciales(viaje.origen)}</p>
                        <li><p><b>Origen: </b>{viaje.origen}</p></li>
                      </div>
                      <img src ={'/train-svgrepo-com.svg'} className = "w-[15%] max-md:w-[30%] disabled:"></img>
                      <div className='w-[40%]'>
                        <p className=' font-bold text-4xl'> {obtenerIniciales(viaje.destino)}</p>
                        <li><p><b>Destino: </b>{viaje.destino}</p></li>
                      </div>
                  </section>
                  <footer className=' flex  flex-col items-start mt-4 text-lg'>
                    <li><p><b>Fecha: </b>{viaje.fecha}</p></li>
                    <li><p><b>Horario: </b>{viaje.hora}</p></li>
                  </footer>
                  <button
                    type='button'
                    onClick = {() => { deleteTicket(viaje._id) }}
                    className="flex items-center gap-1 p-5 font-semibold text-white bg-[#f8566c] rounded-md hover:bg-[#ce4255] self-end"
                  >
                  Cancelar
                  </button>
                </div>
                </>
          })
          : <> Cargando</>}
          </Div2>
          { user.viaje !== null && user.viaje.length !== 0
            ? <div className="flex items-center gap-4 px-4 text-2xl text-center font-bold leading-9 text-green-400 bg-green-100 rounded-md mb-5">
                    <br></br>
                    <p>Estamos buscando su pasaje! Cuando encontremos el boleto se le notificará vía WhatsApp y Email.</p>
                    <br></br>
                </div>
            : <Div3>
                <h3>¡Comience a buscar su viaje!</h3>
                <button
                      onClick={ () => {
                        navigate('/ticket')
                      }}
                      className="flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-800"
                    >
                Nuevo pasaje
                </button>
              </Div3>}
        </Div>
  </StyledForm>
  )
}

const Div = styled.div`
    display:flex;
    flex-direction:column;
    text-align:left;
    justify-content:space-between;

    h4{
        margin-bottom:5px;
    }
`

const Div2 = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    text-align:left;
    margin-top:20px;
    justify-content:space-between;
    align-items:center;

    h2{
        margin:5px;
    }
`

const Div3 = styled.div`
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;

    button{
      width:175px;
      height:55px;
    }



`

export default ReciboPasaje
