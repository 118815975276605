import styled from 'styled-components'

const MainCointainer = styled.section`
    width:100%;
    height:100vh;
    overflow-y:scroll;
    position:relative;
`

const FlexBox = styled.section`
    display:flex;
    flex-direction:row;
    position:relative;
    width:100%;
    align-content:baseline;
    background-color:white;

    .fondo{
        position:absolute;
        bottom:0%;
        right:0%;
    }
`

const Box1 = styled.div`
    width:50%;
    height:100%;
    margin-left:250px;

    img{
        max-width:100%;
        height:100%;
    }
`

const Box2 = styled.div`

    width:50%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    background-color:#0249FD;
    padding-left:100px;
    margin-right:250px;
`

const Descripction = styled.div`
    margin-top:50px;
    position:relative; 
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    align-content:flex-start;
    transition:2s;
    width:100%;

    h1{
        font-size:6em;
        font-weight:700;
    }

    h2{
        font-weight:600;
    }

    button{
            margin-top:20px;
            border: none;
            color:#fff;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 1px;
            border-radius: 15px;
            background-color:#4070f4;
            cursor: pointer;
            transition: all 0.3s ease;
            width:175px;
            height:55px;
    }

    button:hover{
        color:#000000;
    }
`

export { Descripction, FlexBox, MainCointainer, Box1, Box2 }
