import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { mailValidation, passwordValidation } from '../../../utils/inputValidation'
import { Input, InputError } from '../../Forms'
import PropTypes from 'prop-types'
import { mobileMax, config } from '../../../constants'
import styled from 'styled-components'
import { TitleWithLine } from '../../../components'
import { Link } from 'react-router-dom'
import useForm2 from '../../../hooks/useUser'
import googleService from '../../../services/googleService.js'
// import { useDispatch } from 'react-redux'

export const FormLogin = () => {
  const [error, setError] = useState(false)
  const [loginExitoso, setLoginExitoso] = useState(false)
  // const dispatch = useDispatch()
  const methods = useForm({ shouldUnregister: false })
  const usuario2 = useForm2({
    mail: '',
    password: ''
  })

  const onSubmit = methods.handleSubmit(async data => {
    usuario2.changeValue(data)

    try {
      await googleService.login(data.mail, data.password)
      setError({ estado: false, mensaje: '' })
      setLoginExitoso(true)
      // googleService.checkUser(dispatch)
      window.location = config.url.LOCAL_URL
    } catch (e) {
      setError(true)
    }
  })

  // const mandarEmail = async (email) => {
  //   try {
  //     // mandamos email
  //     // si se mando pasamos a la siguiente pagina
  //     await login.mandarEmailRecover()
  //   } catch (e) {
  //     console.log('Email inexistente')
  //   }
  // }

  // const onSubmit = methods.handleSubmit(data => {
  //
  // })

  // Para que cada vez que se desmonte la componente se recuperen con los valores anteriores.

  useEffect(() => {
    // methods.setValue('origen', viaje.getValue().origen)
    // methods.setValue('destino', viaje.getValue().destino)
    // methods.setValue('pasajeros', viaje.getValue().pasajeros)
    // methods.setValue('fecha', viaje.getValue().fecha)
  }, [])

  return (
    <FormProvider {...methods} >
      <StyledForm3 onSubmit={ e => e.preventDefault()}
        noValidate
        className='container'>
          <Input {...mailValidation}/>
          <Input {...passwordValidation}/>

          <button
            onClick={onSubmit}
            className="flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-800"
          >
          Ingresar
          </button>
          {loginExitoso && (
            <p className="flex items-center gap-1 px-2 font-semibold text-green-500 bg-green-100 rounded-md mb-5">
              Registro exitoso
            </p>
          )}
          {error === true ? <InputError message = {'Email o Contraseña incorrectos.'}></InputError> : '' }
      </StyledForm3>
      <p><Link to = "/lostPassword"><u>Olvidaste tu contraseña? </u></Link></p>
      <TitleWithLine frase = {'O continua con '}></TitleWithLine>
      <BotonesContainer>
      <button onClick = {() => googleService.redirectToGoogleSSO()}className="google flex relative items-center py-2 px-4 bg-white border border-gray-300 rounded text-gray-700 text-lg font-bold cursor-pointer transition duration-150 ease-in-out">
          <span className="absolute left-2 top-1/2 transform -translate-y-1/2">
          </span>
          Sign in with Google
        </button>

        <p><Link to = '/registrarse' ><u>¿No tenés cuenta? Registrate</u></Link></p>
      </BotonesContainer>
    </FormProvider>
  )
}

FormLogin.propTypes = {
  success: PropTypes.boolean,
  handleSuccess: PropTypes.func,
  handleNext: PropTypes.func,
  viaje: PropTypes.object
}

const BotonesContainer = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:0px; 
    margin-bottom:20px;
    width: 100%;

    p{
      margin-top:10px;
      text-align:center;
      font-weight:500;
      cursor:pointer;

      &:hover{
        font-weight:600;
      }
    }

    button{
      height:100%;
      font-size:0.75rem;
      margin-inline:5px;
      margin-top:5px;
      margin-bottom:5px;
      height:40px;
    }

    .google:hover{
      background-color:#DB4437;
      color:#fff;

    }

    .microsoft:hover{
      color:#fff;
      background-color:#7DBC00;
    }

`
const StyledForm3 = styled.div`
    background-color:rgb(255 255 255)
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    
    @media ${mobileMax}{
        text-align:center;

        label{
          padding-left:0px;
          align-self:center;
      }
    }

    div{
      width:100%;
    }


    button{
      margin-top:5px;
      margin-bottom:10px;
      width:100%;
      height:40px;
      font-weight:400;
      font-size:0.8rem;
    }


    input, select{
        outline: none;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius 5px;
        border-color:rgb(209 213 219);
        border-width: 1px;
        border-radius: 0.375rem;
        padding: 0 15px;
        height: 41px;
        margin: 8px 0;
        width: 100%;
    }

    label{
        font-size: 0.875rem;
        font-weight: 500;
        color: #2e2e2e;
        align-self:baseline;
    }


    h1 , h2{
        font-weight:500;
        margin:  10px 10px 10px 10px;
    }

`
