import styled from 'styled-components'
import { mobileMax } from '../../../constants'

const ContainerFormInput = styled.div`
    display:flex;
    flex-direction:column;
    width:50%;
    align-items:center;

    @media ${mobileMax}{
        width:80%;

        input,button,select{
            width:100%;
        }
    }

    p{
        font-size:{
            font-size: 0.9em;
            text-align: center;
        }
    }

`

export default ContainerFormInput
