import React, { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Input, InputError } from '../Forms'
import { otpValidation } from '../../utils/inputValidation'
import styled from 'styled-components'
import { mobileMax } from '../../constants'
import PropTypes from 'prop-types'
import login from '../../services/login'

export const FormOTP = ({ email, setPage, setOTP }) => {
  const [error, setError] = useState(false)
  const [otpDisabled, setOtpDisabled] = useState(true)
  const [timer, setTimer] = React.useState(3)
  // const dispatch = useDispatch()
  const methods = useForm({ shouldUnregister: false })
  //   const usuario2 = useForm2({
  //     mail: '',
  //     password: ''
  //   })

  const generarOtp = async () => {
    if (otpDisabled) return
    // Vuelvo a mandar el mail
    await login.mandarEmailRecover(email)
    setOtpDisabled(true)
    setTimer(15)
  }

  const onSubmit = methods.handleSubmit(async data => {
    console.log('Verificamos si es correcto el otp', data)
    try {
      await login.chequearOTP(data.otp, email)
      setError(false)
      setOTP(data.otp)
      setPage(2)
    } catch (e) {
      setError(true)
    }
  })

  useEffect(() => {
    console.log(timer)
    const interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval)
        if (lastTimerCount <= 1) setOtpDisabled(false)
        if (lastTimerCount <= 0) return lastTimerCount
        return lastTimerCount - 1
      })
    }, 1000) // each count lasts for a second
    // cleanup the interval on complete
    return () => clearInterval(interval)
  }, [otpDisabled])

  return (
      <FormProvider {...methods} >
        <StyledForm3 onSubmit={ e => e.preventDefault()}
          noValidate
          className='container'>
            <p>Se envio el mail correctamente a : {email}</p>
            <br></br>
            <Input {...otpValidation}/>

            <button
              onClick={onSubmit}
              className="flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-800"
            >
            Ingresar código
            </button>
            {error === true ? <InputError message = {'código Incorrecto'}></InputError> : '' }
            <button
                onClick={generarOtp}
                disabled = {otpDisabled}
                style={otpDisabled ? { backgroundColor: 'grey' } : { backgroundColor: 'rgba(37, 99, 235 ,1)' }}
                className={'flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md '}
                > Reenviar mail</button>
        </StyledForm3>
      </FormProvider>
  )
}

FormOTP.propTypes = {
  email: PropTypes.string,
  setPage: PropTypes.func,
  setOTP: PropTypes.func

}

const StyledForm3 = styled.div`
    background-color:rgb(255 255 255)
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-bottom:20px;
    
    @media ${mobileMax}{
        text-align:center;

        label{
          padding-left:0px;
          align-self:center;
      }
    }

    div{
      width:100%;
    }


    button{
      margin-top:5px;
      margin-bottom:10px;
      width:100%;
      height:40px;
      font-weight:400;
      font-size:0.8rem;
    }


    input, select{
        outline: none;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius 5px;
        border-color:rgb(209 213 219);
        border-width: 1px;
        border-radius: 0.375rem;
        padding: 0 15px;
        height: 41px;
        margin: 8px 0;
        width: 100%;
    }

    label{
        font-size: 0.875rem;
        font-weight: 500;
        color: #2e2e2e;
        align-self:baseline;
    }


    h1 , h2{
        font-weight:500;
        margin:  10px 10px 10px 10px;
    }

`
