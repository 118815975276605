import axios from 'axios'
import { setViaje } from '../features/auth/userSlice'
//  const productionUrl = 'https://ecommercecoderr.herokuapp.com/api/carrito'
import { config } from '../constants'
const baseUrl = config.url.API_URL + '/api/pasaje'

const createTicket = async (informacion) => {
  try {
    const response = await axios.post(baseUrl, informacion, { withCredentials: true })
    return response.data.success
  } catch (e) {
    throw new Error()
  // RETORNA SUCCESS TRUE SI EL PASAJE NO EXISTE
  // RETONAR SUCCESS FALSE SI LE PASAJE EXISTE
  }
}

const getTickets = async (dispatch) => {
  const response = await axios.get(baseUrl, { withCredentials: true })
  let viajes = response.data
  if (viajes.length >= 1) {
    viajes = viajes.filter(viaje => new Date() <= new Date(viaje.fecha))
  }
  // viajesFiltrados = response.data.filter()
  dispatch(setViaje(viajes))
}

const deleteTicket = async (id) => {
  try {
    const response = await axios.delete(`${baseUrl}/?idTicket=${id}`, { withCredentials: true })
    return response.data.success
  } catch (e) {
    throw new Error()
  }
}

const checkTicket = () => {

}

export default {
  createTicket,
  getTickets,
  checkTicket,
  deleteTicket
}
