import styled from 'styled-components'
import { mobileMax } from '../../constants'

const FooterStyled = styled.div`
    background-color:#2D2D2D;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:75px;
    padding:15px;
    padding-left:20px;

    img{
        color:#ffffff;
        width:10%;
        font-weight:600;
    }

    @media ${mobileMax}{
        img{
            color:#ffffff;
            width:30%;
            font-weight:600;
        }
    }

`

export { FooterStyled }
