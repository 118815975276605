import { findInputError } from '../../../utils/findInputError'
import { isFormInvalid } from '../../../utils/isFormInvalid'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { InputError } from './InputError'
import ContainerFormInput from '../FormNuevoViaje/FormInput.styles'

export const Input = ({ label, type, id, placeholder, validation, name, aclaracion, handleChange, disabled }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext({ shouldUnregister: false })

  const inputError = findInputError(errors, name)
  const isInvalid = isFormInvalid(inputError)
  // PASAJES A PARTIR DE FEBRERO
  const currentDate = new Date().toISOString().split('T')[0]
  return (
    <ContainerFormInput>
          <label htmlFor={id} className="font-semibold">
            {label}
          </label>
          <p>{aclaracion}</p>
          <AnimatePresence mode="wait" initial={false}>
            {isInvalid && (
              <InputError
                message={inputError.error.message}
                key={inputError.error.message}
              />
            )}
          </AnimatePresence>
        <input
          id={id}
          type={type}
          disabled = {disabled}
          min = {currentDate}
          placeholder={placeholder}
          onInput={handleChange}
          {...register(name, validation, { shouldUnregister: false })}
        />
      </ContainerFormInput>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.boolean,
  aclaracion: PropTypes.string,
  validation: PropTypes.Object,
  handleChange: PropTypes.func
}
