import React from 'react'

import { Descripction, FlexBox, TextContainer, Logotipo } from './Cover.styles.js'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux/es/hooks/useSelector'

const Cover = () => {
  const user = useSelector(state => state.user)

  return (
            <FlexBox>
                  {/* <Titulo>
                  </Titulo> */}
                  <Descripction>
                    {/* <ImgContainer>
                      <img src = {Tren} alt = 'imagen de un tren'></img>
                    </ImgContainer> */}
                    <TextContainer>
                      <img className = "logo" src = "./logoblanco.svg"/>
                      <Logotipo >
                        <h1>¿NO CONSEGUÍS PASAJE?</h1>
                        <h3>Nosotros <i>te chiflamos</i></h3>
                        <img className = 'flecha'src = "./flecha4.svg"/>
                        {user.isAuth ? <Link to = '/ticket'><button> BUSCAR PASAJE</button></Link> : <Link to = '/login'><button>INGRESÁ ACÁ</button></Link> }
                      </Logotipo>
                      <div className = "info">
                        <h4>Más información</h4>
                      </div>
                      <img className = 'flecha2'src = "./flecha2.svg"/>
                    </TextContainer>
                  </Descripction>
            </FlexBox>
  )
}
export default Cover
