import React from 'react'
import { StyledBurguer } from './StyledBurguer'
import PropTypes from 'prop-types'

const Burguer = ({ open, changeValue }) => {
  return (
    <StyledBurguer open = {open} onClick = {() => changeValue()} >
        <div></div>
        <div></div>
        <div></div>
    </StyledBurguer>
  )
}

Burguer.propTypes = {
  open: PropTypes.bool,
  changeValue: PropTypes.func
}

export default Burguer
