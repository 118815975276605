import React from 'react'
import { FlexBox, StyledForms2, Flex } from '../Forms/Forms.styles'
import { Title } from '../../components'
import ReciboPasaje from './ReciboPasaje'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { InputError } from '../Forms'

const PlantillaReciboPasaje = () => {
  const user = useSelector(state => state.user)
  return (
    <FlexBox>
    <StyledForms2>{
      !user.isValidate
        ? <InputError message = "Primero debe realizar la verificacion de usuario"></InputError>
        : <>
      <Flex>
        <Title name = 'Usuario'> Informacion: </Title>
      </Flex>
      <ReciboPasaje></ReciboPasaje>
      </>
    }
    </StyledForms2>
    {/* <StyledPuntos>
        <StyledLi isSelected = {index.getValue() === 0} ><button>1</button></StyledLi>
        <StyledLi isSelected = {index.getValue() === 1}><button>2</button></StyledLi>
    </StyledPuntos> */}
  </FlexBox>
  )
}

export default PlantillaReciboPasaje
