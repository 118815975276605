// userSlice.js

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  info: null,
  isAuth: false,
  loading: true,
  error: null,
  isValidate: null,
  viaje: null,
  limitePasajes: false,
  modalState: 'INACTIVE',
  ticketToDelete: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuthenticated (state, action) {
      state.isAuth = action.payload
    },
    setUser (state, action) {
      state.info = action.payload
    },
    registerUserFailure (state, action) {
      state.loading = false
      state.error = action.payload
    },
    setLoading (state) {
      state.loading = false
    },
    setValidate (state, action) {
      state.isValidate = action.payload
    },
    setViaje (state, action) {
      state.viaje = action.payload
    },
    setPasajesLimite (state) {
      state.limitePasajes = true
    },
    setModalStateActive (state) {
      state.modalState = 'ACTIVE'
    },
    setModalStateInActive (state) {
      state.modalState = 'INACTIVE'
    },
    setTicketToDelete (state, action) {
      state.ticketToDelete = action.payload
    }
  }
})

export const { setIsAuthenticated, setUser, registerUserFailure, setValidate, setLoading, setViaje, setPasajesLimite, setModalStateActive, setModalStateInActive, setTicketToDelete } = userSlice.actions

export default userSlice.reducer
