import React, { useEffect, useState } from 'react'
import { StyledForm } from '../Forms.styles'
import PropTypes from 'prop-types'

import { FormProvider, useForm } from 'react-hook-form'
import { nombreValidation, apellidoValidation, apodoValidation, dniValidation, nroTramiteValidation, generoValidation, codAreaValidation, numeroValidation, correoValidation, passwordTrenesValidation, correoTrenesValidation } from '../../../utils/inputValidation'
import { Input, InputError } from '../index'
import { Select } from '../components/Select'
import googleService from '../../../services/googleService'
import { useDispatch } from 'react-redux'
import validarDatosService from '../../../services/checkerDatosService'
import { LoadingSpinner } from '../../../components'

export const FormDatosUsuario = ({ success, handleSuccess, handleNext, info }) => {
  const methods = useForm({ shouldUnregister: false })
  const [datosValidados, setDatosValidados] = useState(false)
  const [datosTelefono, setDatosTelefono] = useState(false)
  const [datosDni, setDatosDni] = useState(false)
  const [datosLogin, setDatosLogin] = useState(false)
  const [loading, setLoading] = useState(false)

  const delay = ms => new Promise((resolve) => setTimeout(resolve, ms))
  const dispatch = useDispatch()

  const onSubmit = methods.handleSubmit(async data => {
    // dni-condition y login-condition
    setDatosTelefono(false)
    setDatosDni(false)
    setDatosLogin(false)
    setLoading(true)
    let bandera = true
    try {
      const conditions = await validarDatosService.validar(data.dni, data.nroTramite, data.genero, data.correoTrenes, data.passwordTrenes)

      if (!conditions.dni_condition) {
        setDatosDni(true)
        bandera = false
      }
      if (!conditions.login_condition) {
        setDatosLogin(true)
        bandera = false
      }
    } catch (e) {
      setDatosLogin(true) // Esto hay que cambiarlo por un error general
      bandera = false
    }

    // number-condition
    if ((data.codArea.length + data.numero.length) !== 10) {
      setDatosTelefono(true)
      bandera = false
    }

    if (bandera) {
      setLoading(false)
      info.changeValue(data)
      // cambiar el mail a miniscula
      console.log(info.correoTrenes)
      googleService.setDatosUsuario(data)
      setDatosValidados(true)
      await delay(3000)
      googleService.checkUser(dispatch)
    }
    setLoading(false)
  })

  // Para que cada vez que se desmonte la componente se recuperen con los valores anteriores.

  useEffect(() => {
    methods.setValue('nombre', info.getValue().nombre)
    methods.setValue('apellido', info.getValue().apellido)
    methods.setValue('apodo', info.getValue().apodo)
    methods.setValue('dni', info.getValue().dni)
    handleSuccess(false)
  }, [])

  return (
    <FormProvider {...methods} >
      <StyledForm onSubmit={ e => e.preventDefault()}
        noValidate
        className='container'>

          <Input {...nombreValidation}/>
          <Input {...apellidoValidation}/>
          <Input {...apodoValidation}/>
          <Input {...dniValidation}/>
          <Input {...nroTramiteValidation}/>
          <Select {...generoValidation}/>
          <Input {...codAreaValidation}/>
          <Input {...numeroValidation}/>
          <Input {...correoValidation}/>
          <Input {...correoTrenesValidation}/>
          <Input {...passwordTrenesValidation}/>
      </StyledForm>
      <p className='linkTrenes'>¿No tenés cuenta de Trenes Argentinos? <a href = "http://techiflo.com/trenes" target = "_blank" rel="noreferrer"><u>Ingresá acá</u></a></p>
      {datosValidados && (
            <p className="flex items-center gap-1 px-2 font-semibold text-green-500 bg-green-100 rounded-md mb-5">
              Datos del usuario ingresados correctamente
            </p>
      )}
      {
        <LoadingSpinner loading = {loading}></LoadingSpinner>
      }
      {datosTelefono && (
            <InputError message = "Cod de Area y/o Numero de Telefono incorrectos"/>
      )}
      {datosDni && (
            <InputError message = "Dni, Nro Tramite y/o Genero incorrectos"/>
      )}
      {datosLogin && (
            <InputError message = "Email y/o contrasena trenes argentinos incorrectos"/>
      )}
      <button
            onClick={onSubmit}
            className="flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-800"
          >
            Siguiente
      </button>
    </FormProvider>
  )
}

FormDatosUsuario.propTypes = {
  success: PropTypes.boolean,
  handleSuccess: PropTypes.func,
  handleNext: PropTypes.func,
  info: PropTypes.object
}

export default FormDatosUsuario
