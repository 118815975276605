import styled from 'styled-components'
import { mobileMax } from '../../constants'

const StyledForm = styled.form`

    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    width: 100%;
    margin-bottom:10px;
    padding-top:10px;
    align-self:center;
    margin-left:30px;
    margin-top:10px;

    @media ${mobileMax}{
        flex-direction:column;
        margin-left:0px;
    }

    input,button{
        width:90%;
    }
    select{
        width:90%;
    }
    
    
    .boleteria{
        display:flex;
        justify-content:center;
        align-items:center;
        width:50%;

        button{
            width:90%;
            color: #ffffff;
            margin-top:1.75rem;
        }

        @media ${mobileMax}{
            width:80%;

            button{
                width:90%;
            }
        }

        #fecha{
            border-color:red;
            input{
                appearance: none;
            }
        }
    }


    .buscandoPasaje{


        
    }

`

const FlexBox = styled.div`
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    height:100vh;

    background:#cbcbcb;
    padding-top:70px;
    overflow:scroll;

    @media ${mobileMax}{
        padding-top:140px !important;
    }
    
`

const Flex = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;

    p{
        color:rgb(37, 99, 235);

        &:hover{
            font-weight:500;
            cursor:pointer;
            border-bottom: 1px solid;
        }
    }


`

const StyledForms2 = styled.div`
    background-color:rgb(255 255 255);
    width:70%;
    border-radius: 16px;
    border: 2px solid #E8E8E8;
    margin: 10px auto;
    padding: 0px 36px;
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-bottom:100px;

    @media ${mobileMax}{
        text-align:center;
        width:95%;
        padding: 0px 10px;
        
        button{
            width:80%;
        }
    }


    input, select{
        outline: none;
        font-size:14px;
        font-weight: 400;
        border-radius 5px;
        border-color:rgb(209 213 219);
        border-width: 1px;
        border-radius: 0.375rem;
        padding: 0 15px;
        height: 42px;
        margin: 8px 0;
    }

    label{
        font-size: .875rem;
        font-weight: 600;
        color: #2e2e2e;
    }
    
    button{
        margin-top: 5px;
        margin-bottom: 10px;
        width: 40%;
        justify-content:center;
        height: 40px;
        font-weight: 400;
        font-size: 0.8rem;
        border: 1px solid #e5e7eb;
    }


    h1 , h2{
        font-weight:600;
        margin:  10px 10px 10px 10px;
    }

`

const ButtonsDiv = styled.div`
        display:flex;
        flex-direction:row;
        justify-content: center;

        button{
            margin-top: 5px;
            margin-bottom: 10px;
            width: 100%;
            height: 40px;
            font-weight: 400;
            font-size: 0.8rem;
            background: #fff;
            color: #4070f4;
            border: 1px solid #e5e7eb;
        }

        .button2{
            width: 60px;
            height: 60px;
            background: #fff;
            color: #4070f4;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            text-decoration: none;
            transition: background 0.2s;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1px;
          }
          
          .button1:hover,.button2:hover {
            background: #454ade;
          }

        .button1{
            font-size: 17px;
            background-color: #fffff;
            color: #4070f4;
            font-weight: 500;
            letter-spacing: 1px;
            border-radius: 6px;
            cursor: pointer;
            transition: all 0.3s ease;
            width:150px;
        }
`

export { StyledForm, StyledForms2, ButtonsDiv, FlexBox, Flex }
