import styled from 'styled-components'
import { mobileMax } from '../../../constants'

const Section = styled.section`
    display:flex;
    background-color:#0249FD;
    width:100%;

`
const Container = styled.div`
    width:100%;  
    display:flex;
    flex-direction:${props => props.flexDirection}; 
    padding: 10px 0px 0px 0;
    align-items:center;

    
    @media ${mobileMax}{
        flex-direction:column;

    }
`
const ImgContainer = styled.div`
    width:40%;
    padding:0px 150px 50px 50px;

    img{
        max-height:100%;
        max-width:100%;
    }

    @media ${mobileMax}{
        display:none;
    }
`

const TextContainer = styled.div`
    width:60%;
    display:flex;
    padding-left: ${props => props.paddingLeft ? '50px' : '0px'};
    flex-direction:column;

    a{
        width:100%;
    }
    
    img{
        height:30px;
        width:30px;
        margin-right:30px;
    }

    li{
        margin-top:15px;
        display:flex;
        margin-bottom:15px;
    }

    h2{
        text-align:center;
        padding-bottom:12.5px;
        font-size:1.65rem;
        margin-bottom:25px;
    }

    h3{
        margin-bottom:5px;
    }
    
    h4{
        margin-bottom:0.5px;
        font-weight:500;
    }

    div{
        padding-left:25px;
    }

    button{
        margi-top:20px;
        margin-bottom:20px;
        padding-inline:20px;
        border: none;
        color:#fff;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
        border-radius: 15px;
        background-color:#4070f4;
        cursor: pointer;
        width:100%;
        height:45px;
    }

    button:hover{
        color:#000000;
    }

    .button2{
        background-color:rgb(0,0,0,0);
        color:#000000;
    }

    .button2:hover{
        text-decoration:underline;
    }

    @media ${mobileMax}{
        padding-left:0px;
        div{
            padding-left:0px;
        }

        h2{
            margin-bottom:0px;
        }
    }
`

const ButtonContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    
    @media ${mobileMax}{
        flex-direction:column;


        h3{
            visibility:hidden;
        }
    }
`

export { Section, Container, ImgContainer, TextContainer, ButtonContainer }
