import axios from 'axios'
import { config } from '../constants'
const baseUrl = config.url.API_URL + '/checker'

const validar = async (dni, nroTramite, genero, correoTrenes, password) => {
  const datos = {
    dni,
    nroTramite,
    genero,
    correoTrenes,
    password
  }
  try {
    const response = await axios.post(`${baseUrl}/dni-condition`, datos, { withCredentials: true })

    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Error:\t')
    }
  } catch (e) {
    throw new Error('Error:\t ')
  }
}

const nuevoUsuarioTrenes = async (nombre, apellido, dni, correoTrenes, password) => {
  const data =
    {
      nombre_registro: nombre,
      apellido_registro: apellido,
      dni_registro: dni,
      mail_registro: correoTrenes,
      password_registro: password
    }
  try {
    const response = await axios.post(`${baseUrl}/register`, data, { withCredentials: true })

    if (response.status === 200 && response.data.status === 1) { return 200 } else {
      throw new Error('Error:\t Mail ya asociado a cuenta de Trenes Argentinos .  Ingrese a https://webventas.sofse.gob.ar/')
    }
  } catch (e) {
    throw new Error('Error:\t Mail ya asociado a cuenta de Trenes Argentinos .   Ingrese a https://webventas.sofse.gob.ar/')
  }
}

const getOrigenes = async () => {
  const response = await axios.post(`${baseUrl}/checker/origenes`, { withCredentials: true })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Error:\t')
  }
}

const getDestinos = async (origen) => {
  const response = await axios.post(`${baseUrl}/checker/destinos`, { withCredentials: true })
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Error:\t')
  }
}

export default {
  validar,
  nuevoUsuarioTrenes,
  getOrigenes,
  getDestinos
}
