import React from 'react'
import { Container, PreInfo } from './Information.styles'
import PropTypes from 'prop-types'
import InformationContainer from './InformationContainer'

const sectionStyled = {
  height: 'auto'
}

const Information = () => {
  return (
    <section style = {sectionStyled} >
        <PreInfo><h3>CÓMO RESERVAR Y PAGAR TU PASAJE DE TREN CON TE CHIFLO?</h3></PreInfo>
        <Container>
            <div>
            <InformationContainer number = "1." title = "Búsqueda de pasajes" description = "Registrate, ingresá los datos de viaje y esperá mientras encontramos un pasaje disponible." description2="Tu cuenta es exclusiva para que solo puedas buscar pasajes a tu nombre." urlFoto={'lupa.svg'} tipo = "par"></InformationContainer>
            <InformationContainer number = "2." title = "Notificación de pasajes" description = "Una vez que encontremos un pasaje disponible, te enviaremos un WhatsApp y Correo Electrónico indicando que tenemos un boleto reservado para vos." urlFoto={'notificacion.svg'} tipo = "impar"></InformationContainer>
            <InformationContainer number = "3." title = "Pago de comisión" description = "Deberás abonar una comisión de $3500 para asegurarte de que el pasaje quede reservado a tu nombre. " description2={'Te enviaremos un link de Mercado Pago para realizar este pago.'} urlFoto={'mp.svg'} tipo = "impar"></InformationContainer>
            <InformationContainer number = "4." title = "Compra del pasaje" description = "Tras pagar la comisión, te enviaremos otro mensaje para finalizar la compra del pasaje mediante un link que tiene una duración de 10 minutos." description2="No se asume responsabilidad si el tiempo expira." urlFoto={'tiempo.svg'} tipo = "par"></InformationContainer>
            <InformationContainer number = "5." title = "Confirmación de compra" description = "Después de realizar la compra, recibirás un correo electrónico con la confirmación de esta y podrás ver el pasaje en tu cuenta de Trenes Argentinos." urlFoto={'ticket.svg'} tipo = "par"></InformationContainer>
            <InformationContainer number = "." title = "Importante"description = "No garantizamos el pasaje." description2={'Las chances de obtener pasaje son muy altas cuando realizás la reserva con anticipación.'} urlFoto={'alarma.svg'} tipo = "alarma"></InformationContainer>
            {/* <InformationContainer number = "." title = ""description = ""></InformationContainer> */}
            </div>
        </Container>
    </section>
  )
}

Information.propTypes = {
  myRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

export default Information
